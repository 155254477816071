import { Component, Input } from '@angular/core';
import { MenuItem } from '../../services/menu.service';
import { ProjectService } from 'src/app/services/project.service';
import {AccessRulesService} from "../../../app/services/access-rules.service";

@Component({
  selector: 'cm-sub-menu',
  templateUrl: './sub-menu.component.html',
  styleUrls: ['./sub-menu.component.scss'],
})
export class SubMenuComponent {
  @Input() items: MenuItem[];

  constructor(
    private projectService: ProjectService,
    private accessRulesService: AccessRulesService,
  ) {}

  hasAccess(item: MenuItem) {
    const accessProjects = this.accessRulesService.getAccessRulesValue().menu[item.type];

    if (accessProjects){
      return this.projectService.validAccessRuleByProject(accessProjects);
    }

    return true;
  }
}
