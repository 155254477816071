<div class="logo-big">
  <img [src]="logo" />
</div>
<div class="forgot-container">
  <h3 class="text-center">Restore Username</h3>
  <div class="form-container">
    <form [formGroup]="form" novalidate (submit)="onSubmit()">
      <div class="control margin-bottom">
        <label for="email">Email</label>
        <lib-cm-input
          placeholder=""
          formControlName="email"
          id="email"
          [titlecase]="false"
        ></lib-cm-input>
      </div>

      <div class="margin-bottom">
        Enter the email address you used to create the account. The username that you specify when
        you register will be sent to your e-mail.
      </div>

      <button type="submit" class="submit-btn" [disabled]="submitBtnDisabled">
        <div class="spinner-rect-container" [class.show]="loading">
          <spinner-rect color="white"></spinner-rect>
        </div>
        <div class="title" [class.not-visible]="loading">Send Email</div>
      </button>

      <div *ngIf="error" class="error-container">
        <span class="error">
          <span class="error-message">
            {{ 'ERROR: ' + error }}
          </span>
        </span>
      </div>
    </form>
  </div>
</div>
