import { Injectable } from '@angular/core';
import { ProjectIs } from 'src/app/models/projects.model';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { AccessRulesService } from './access-rules.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  projectAbbreviation: ProjectIs;

  constructor(
    private authorizationSvc: AuthorizationService,
    private accessRulesService: AccessRulesService,
  ) {
    this.projectAbbreviation = this.accessRulesService.getProject();
  }

  getModelByProject(model: any) {
    if (typeof model !== 'object') {
      return model;
    }

    return model[this.projectAbbreviation] || model;
  }

  /*

    example_1: [ProjectIs.UTMC],

    example_2: {
      [ProjectIs.UTMC]: [],
      [ProjectIs.STOP_CLAIMS]: [UserIs.INTERNAL],
      [ProjectIs.ACME]: [UserIs.INTERNAL],
    }

    example_3: {
      [ProjectIs.UTMC]: [
        {
          roles: [UserIs.INTERNAL.ANALYST],
          tags: []
        },
        {
          roles: [UserIs.CLIENT],
          tags: [UserTags.BILL, UserTags.BILL_],
        }
      ]
    }

   */
  validAccessRuleByProject(rule: ProjectIs[] | { [key in ProjectIs]?: any }): boolean {
    if (Array.isArray(rule)) {
      // Handle the case where the rule is an array of projects
      return rule.includes(this.projectAbbreviation);
    }
    // Handle the case where the rule is a map of projects to roles
    if (!(this.projectAbbreviation in rule)) {
      return false;
    }

    const projectRules = rule[this.projectAbbreviation];
    if (
      Array.isArray(projectRules) &&
      projectRules.length > 0 &&
      typeof projectRules[0] === 'object' &&
      projectRules[0].roles
    ) {
      // TOOD fix
      return this.authorizationSvc.userIsAndHasTag(projectRules);
    }

    // If roles array is empty, allow access for all roles
    if (projectRules.length === 0) {
      return true;
    }

    // Check if the user has one of the specified roles
    return this.authorizationSvc.userIs(projectRules);
  }
}
