<div class="logo" *ngIf="showRegularLogo">
  <img [src]="logo" alt="{{ this.projectInfo.name }} logo" class="project-logo" />
</div>
<div class="logo" *ngIf="showAnalogLogo">
  <img src="assets/img/logo_UTMC_MIIA_410.png" alt="{{ this.projectInfo.name }} logo" class="miip" />
</div>
<button class="menu-toggle" (click)="toggleMenu()">
  <svg
    *ngIf="menuService.isMinimized"
    fill="#000000"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 21h18v-2H3v2zM3 8v8l4-4-4-4zm8 9h10v-2H11v2zM3 3v2h18V3H3zm8 6h10V7H11v2zm0 4h10v-2H11v2z"
    ></path>
    <path d="M0 0h24v24H0z" fill="none"></path>
  </svg>
  <svg
    *ngIf="!menuService.isMinimized"
    fill="#000000"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 17h10v-2H11v2zm-8-5l4 4V8l-4 4zm0 9h18v-2H3v2zM3 3v2h18V3H3zm8 6h10V7H11v2zm0 4h10v-2H11v2z"
    ></path>
    <path d="M0 0h24v24H0z" fill="none"></path>
  </svg>
</button>

<cm-header-title
  [title]="headerTitle"
  [menuItems]="headerItems"
  class="header-title"
  [ngClass]="{ 'with-online-users': showOnlineUsers }"
>
</cm-header-title>

<cm-online-users *ngIf="showOnlineUsers"></cm-online-users>

<div
  *ngIf="showReleaseInfo"
  class="release-info"
  [tooltip]="notificationTpl"
  animationDuration="150"
  contentType="template"
  placement="left"
>
  <svg
    width="37"
    height="18"
    viewbox="0 0 37 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    (click)="showReleaseNotesPopupWindow()"
  >
    <rect
      width="37"
      height="18"
      rx="2"
      id="what-new-in-cms"
      [attr.fill]="hasReadDeployMessage ? '#AEB0B5' : '#E31C3D'"
    ></rect>
    <path
      d="M7.26562 13V5.17599H9.07763L11.1176 9.064L11.8856 10.792H11.9336C11.8936 10.376 11.8456 9.91199 11.7896 9.39999C11.7416 8.88799 11.7176 8.4 11.7176 7.936V5.17599H13.3976V13H11.5856L9.54563 9.09999L8.77762 7.396H8.72962C8.76962 7.828 8.81362 8.292 8.86162 8.788C8.91762 9.284 8.94563 9.764 8.94563 10.228V13H7.26562Z"
      fill="white"
    ></path>
    <path
      d="M15.2461 13V5.17599H20.1421V6.664H17.0101V8.236H19.6741V9.712H17.0101V11.512H20.2621V13H15.2461Z"
      fill="white"
    ></path>
    <path
      d="M22.5163 13L21.0643 5.17599H22.8763L23.4163 8.836C23.4643 9.244 23.5163 9.65599 23.5723 10.072C23.6283 10.48 23.6803 10.892 23.7283 11.308H23.7763C23.8563 10.892 23.9323 10.48 24.0043 10.072C24.0843 9.65599 24.1643 9.244 24.2443 8.836L25.0843 5.17599H26.5843L27.4243 8.836C27.5043 9.236 27.5803 9.64399 27.6523 10.06C27.7323 10.468 27.8123 10.884 27.8923 11.308H27.9403C27.9963 10.884 28.0483 10.468 28.0963 10.06C28.1523 9.65199 28.2083 9.244 28.2643 8.836L28.8043 5.17599H30.4963L29.1043 13H26.8843L26.1163 9.448C26.0523 9.144 25.9923 8.83999 25.9363 8.536C25.8883 8.22399 25.8443 7.924 25.8043 7.63599H25.7563C25.7083 7.924 25.6563 8.22399 25.6003 8.536C25.5523 8.83999 25.4963 9.144 25.4323 9.448L24.7003 13H22.5163Z"
      fill="white"
    ></path>
  </svg>
</div>

<cm-user-controls [user]="user" [userControls]="headerService.userControls"></cm-user-controls>

<ng-template #notificationTpl><span>What`s new in CMS</span></ng-template>
