import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FaviconService {
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  setFavicon(base64: string): void {
    const existingLink = document.querySelector("link[rel*='icon']");

    if (existingLink) {
      this.renderer.setAttribute(existingLink, 'href', base64);
    } else {
      const link: HTMLLinkElement = this.renderer.createElement('link');
      this.renderer.setAttribute(link, 'rel', 'icon');
      this.renderer.setAttribute(link, 'type', 'image/png'); // Adjust if different format
      this.renderer.setAttribute(link, 'href', base64);
      document.head.appendChild(link);
    }
  }
}
