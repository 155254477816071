/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable class-methods-use-this */
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ProfileService } from 'src/app/services/profile.service';
import { ComponentDestroyClass } from 'src/app/shared/classes/component-destroy.class';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from 'src/app/services/app.service';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { UserIs } from 'src/app/models/user-is.model';
import { AiNotificationType } from 'src/app/cms/modules/action-items/models/ai-notification.model';
import { ProjectService } from 'src/app/services/project.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MenuService } from '../services/menu.service';
import { ScreenService } from '../services/screen.service';
import { SidebarService } from '../services/sidebar.service';
import { HeaderService } from '../services/header.service';
import { ReleaseService } from '../services/release.service';
import { ReleaseModalComponent } from './online-users/modal/realease-modal/release-modal.component';
import { AccessRulesService } from '../../app/services/access-rules.service';

@Component({
  selector: 'cm-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [ReleaseService],
})
export class HeaderComponent extends ComponentDestroyClass implements OnInit, OnDestroy {
  headerTitle: any;

  headerItems: any;

  isAdmin: boolean;

  hasReadDeployMessage = true;

  deployInfoMessages: any[];

  sanitizedLogo: SafeUrl;

  constructor(
    public menuService: MenuService,
    private appSvc: AppService,
    private screenService: ScreenService,
    private sidebarService: SidebarService,
    public headerService: HeaderService,
    private profileSvc: ProfileService,
    private cd: ChangeDetectorRef,
    private authSvc: AuthorizationService,
    private releaseSvc: ReleaseService,
    private dialog: MatDialog,
    private projectService: ProjectService,
    private accessRulesService: AccessRulesService,
    private sanitizer: DomSanitizer,
  ) {
    super();
  }

  ngOnInit() {
    this.checkRoles();
    this.profileSvc.getDefaultAvatar().subscribe({
      next: (avatar) => {
        this.profileSvc.user.defaultAvatar = avatar.base64Data;
      },
      error: (err) => {
        console.error('GET DEFAULT AVATAR ERROR:', err);
      },
    });
    this.headerService.title$.pipe(takeUntil(this.componentDestroyed$)).subscribe((title) => {
      this.headerTitle = title;
      this.cd.detectChanges();
    });

    this.headerService.items$.pipe(takeUntil(this.componentDestroyed$)).subscribe((items) => {
      this.headerItems = items;
      this.cd.detectChanges();
    });

    this.initDeployMessages();
  }

  get projectInfo() {
    return this.accessRulesService.getEnvironmentProject();
  }

  get logo() {
    return this.sanitizeBase64(this.accessRulesService.getCompactLogoBase64());
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  private sanitizeBase64(base64: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(base64);
  }

  get isCms() {
    return this.appSvc.rootModule.CMS;
  }

  get isCm() {
    return this.appSvc.rootModule.CM;
  }

  get user() {
    return this.profileSvc.user;
  }

  get showOnlineUsers() {
    return (
      this.appSvc.rootModule.CMS &&
      this.isAdmin &&
      !this.isHideInfo() &&
      this.projectService.validAccessRuleByProject(
        this.accessRulesService.getAccessRulesValue().components.header.show.section.online_users,
      )
    );
  }

  get showReleaseInfo() {
    return (
      this.appSvc.rootModule.CMS &&
      !this.isHideInfo() &&
      !this.authSvc.userIs([UserIs.PARTNER]) &&
      this.projectService.validAccessRuleByProject(
        this.accessRulesService.getAccessRulesValue().components.header.show.section.release_info,
      )
    );
  }

  get showAnalogLogo() {
    return (
      this.projectService.validAccessRuleByProject(
        this.accessRulesService.getAccessRulesValue().components.header.show.logo.miia,
      ) &&
      this.isPartnerMIIAorClientMIIA() &&
      !this.isHideInfo()
    );
  }

  get showRegularLogo() {
    return !this.showAnalogLogo;
  }

  toggleMenu() {
    this.menuService.toggleMenu();
    if (!this.menuService.isMinimized && !this.screenService.isLarge()) {
      this.sidebarService.isMinimized = true;
    }
  }

  isHideInfo(): boolean {
    return window.innerWidth < 840;
  }

  showReleaseNotesPopupWindow() {
    const markedAsRead = this.deployInfoMessages.filter((msg) => !msg.read).map((msg) => msg.id);
    this.releaseSvc.markAllAsRead(markedAsRead).then((response) => {
      this.hasReadDeployMessage = true;
    });
    this.dialog.open(ReleaseModalComponent, {
      maxWidth: '600px',
      closeOnNavigation: true,
      data: {
        closeBtn: true,
        messages: this.getReleasesInfo(),
      },
    });
  }

  isPartnerMIIAorClientMIIA(): boolean {
    // patnerId would be equals 1 for MIIA, if program set MIIA,but programId not equals 1, then check database
    return this.profileSvc.user.partnerId === 1;
  }

  private initDeployMessages() {
    this.releaseSvc
      .getNotifications({
        skip: 0,
        take: 20,
        type: AiNotificationType.RELEASE_DEPLOY,
        sortField: 'created',
        sortDirection: 'desc',
      })
      .subscribe({
        next: (response) => {
          this.deployInfoMessages = response.data;
          this.hasReadDeployMessage = response.data.every((item) => item.read === true);
        },
      });
  }

  private getReleasesInfo() {
    return this.deployInfoMessages.map((item) => `<div>${item.message}</div>`).join('');
  }

  private checkRoles() {
    this.isAdmin = this.authSvc.userIs([UserIs.INTERNAL.ADMIN]);
  }
}
