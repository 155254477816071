<div class="logo-big">
  <img [src]="logo" />
</div>
<div class="login-container">
  <h3 class="text-center">Log in to CMS</h3>
  <div class="form-container">
    <form [formGroup]="form" novalidate (submit)="onSubmit()">
      <div class="control">
        <label for="login">Username</label>
        <lib-cm-input
          placeholder=""
          formControlName="login"
          id="atqa-login"
          [titlecase]="false"
          [clearable]="true"
        ></lib-cm-input>
      </div>
      <div class="link margin-bottom">
        <span class="forgot-username" (click)="forgotUsername()">Forgot username</span>
      </div>
      <div class="control password-input">
        <label for="password">Password</label>
        <lib-cm-input
          #passwordControl
          placeholder=""
          [type]="passwordVisibility ? 'text' : 'password'"
          formControlName="password"
          id="atqa-password"
          [titlecase]="false"
        ></lib-cm-input>
        <div class="password-visibility" (click)="onChangePasswordVisibility()">
          <span *ngIf="passwordVisibility" class="material-icons"> visibility </span>
          <span *ngIf="!passwordVisibility" class="material-icons"> visibility_off </span>
        </div>
      </div>
      <div class="link margin-bottom">
        <a routerLink="/password-email">Forgot password</a>
      </div>
      <div *ngIf="pinRequired" class="control margin-bottom">
        <label for="pin">PIN</label>
        <lib-cm-input
          #pinControl
          placeholder=""
          formControlName="pin"
          id="atqa-pin"
          [titlecase]="false"
        ></lib-cm-input>
      </div>
      <div class="remember-me margin-bottom">
        <lib-cm-checkbox
          label="Remember me"
          formControlName="rememberMe"
          id="atqa-remember-me"
        ></lib-cm-checkbox>
        <span
          [tooltip]="rememberMeInfo"
          contentType="template"
          placement="right"
          class="material-icons remember-me-info-icon"
          tooltipClass="remember-me-info-message"
        >
          help
        </span>
      </div>

      <button type="submit" class="login-btn" [disabled]="loginBtnDisabled" id="atqa-login-btn">
        <div class="spinner-rect-container" [class.show]="loading">
          <spinner-rect color="white"></spinner-rect>
        </div>
        <div class="title" [class.not-visible]="loading">Log in</div>
      </button>
      <div *ngIf="error" class="error-container">
        <span *ngIf="status === 401" class="error">
          <i class="material-icons error-icon">report_problem</i>
          <span class="error-message">
            {{ error }}
          </span>
        </span>
        <span *ngIf="status !== 401" class="error">
          <span class="error-message">
            {{ 'ERROR: ' + error }}
          </span>
        </span>
      </div>
    </form>
  </div>
</div>

<ng-template #rememberMeInfo>
  <span
    >Checking “Remember Me” will save your username and station, allowing access without a secure
    PIN.<br />
    <b>Do not check</b> this box if you’re using a <b>public</b> or <b>shared</b> station, such as
    in a library or Internet café to avoid unapproved access to your account.</span
  >
</ng-template>
