import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccessRulesService } from '../../services/access-rules.service';

@Component({
  selector: 'cms-deploy-popup',
  templateUrl: './deploy-popup.component.html',
  styleUrls: ['./deploy-popup.component.scss'],
})
export class DeployPopupComponent implements OnInit {
  secondsLeft: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private accessRulesService: AccessRulesService,
  ) {}

  ngOnInit(): void {
    this.secondsLeft = this.data.socketMsg.delay;

    setInterval(() => {
      this.secondsLeft--;
    }, 1000);
  }

  get projectInfo() {
    return this.accessRulesService.getEnvironmentProject();
  }
}
