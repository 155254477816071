import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccessRulesService } from '../../../../../app/services/access-rules.service';

@Component({
  selector: 'cm-utmc-users-modal',
  templateUrl: './utmc-users-modal.component.html',
  styleUrls: ['./utmc-users-modal.component.scss'],
})
export class UtmcUsersModalComponent implements OnInit {
  internalUsers: any[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private accessRulesService: AccessRulesService,
  ) {}

  ngOnInit() {
    this.internalUsers = this.data.internalUsers;
  }

  get projectInfo() {
    return this.accessRulesService.getEnvironmentProject();
  }
}
