import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { DeployService } from 'src/app/services/deploy.service';
import { IdleService } from 'src/app/services/idle.service';
import { WebSocketService } from 'src/app/services/web-socket.service';
import { UserRevokeService } from '../../services/general/user-revoke.service';
import { ProfileService } from '../../services/profile.service';
import { AccessRulesService } from '../../services/access-rules.service';

declare let YTFeedbackForm: any;

@Component({
  selector: 'cms-authorization',
  templateUrl: './authorization.component.html',
  styleUrls: ['./authorization.component.scss'],
  providers: [UserRevokeService],
})
export class AuthorizationComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('ytWidgetContainer', { static: false }) ytWidgetContainer!: ElementRef;

  widgetId = '0807af02-0aa5-42b7-b2d2-11571759624c';

  widgetBackendUrl = 'https://plextera.youtrack.cloud';

  private clickListener!: () => void;

  private mutationObserver!: MutationObserver;

  private popupButtonMutationObserver!: MutationObserver;

  private formApi: any;

  constructor(
    private webSocketSvc: WebSocketService,
    private idleSvc: IdleService,
    private userRevokeService: UserRevokeService,
    private deploySvc: DeployService,
    private profileService: ProfileService,
    private accessRulesService: AccessRulesService,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.idleSvc.start();
    this.webSocketSvc.connect();
    this.userRevokeService.subscribe();
    this.deploySvc.startTracking();
  }

  ngAfterViewInit(): void {
    if (!(window as any).YTFeedbackForm) {
      this.loadScript(
        'https://plextera.youtrack.cloud/static/simplified/form/form-entry.js?auto=false',
      )
        .then(() => this.initializeWidget())
        .catch((error) => console.error(error));
    } else {
      this.initializeWidget();
    }

    this.attachPopupClickListener();
    this.attachPopupButtonClickListener();
  }

  ngOnDestroy(): void {
    this.idleSvc.stop();
    this.userRevokeService.unsubscribe();
    this.deploySvc.stopTracking();
    this.webSocketSvc.close();
    if (this.clickListener) {
      this.clickListener();
    }
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
  }

  private loadScript(src: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.onload = () => resolve();
      script.onerror = () => reject(new Error(`Script load error for ${src}`));
      document.body.appendChild(script);
    });
  }

  private initializeWidget(): void {
    YTFeedbackForm.renderFeedbackButton(this.ytWidgetContainer.nativeElement, {
      backendURL: this.widgetBackendUrl,
      formUUID: this.widgetId,
      theme: 'light',
      language: 'en',
    });

    YTFeedbackForm.getClientJSApi(this.widgetId).then((form: any) => {
      this.formApi = form;
      this.setFormValues();
    });
  }

  private setFormValues(): void {
    if (this.formApi) {
      this.formApi.setBlockValue('Email address', this.profileService.user.email);
      this.formApi.setBlockValue('Contact Name', this.profileService.user.name);
      this.formApi.setBlockValue('Client', this.accessRulesService.getProjectName());
      this.formApi.setBlockValue('Site Url', window.location.href);
    }
  }

  private attachPopupClickListener(): void {
    this.mutationObserver = new MutationObserver((mutations, obs) => {
      const popupElement = document.querySelector(
        'div[data-test="ring-popup form-popup"][data-test-shown="true"]',
      );
      if (popupElement) {
        this.clickListener = this.renderer.listen(popupElement, 'click', (event: Event) => {
          const target = event.target as HTMLElement;
          const desiredElement = target.closest('[class*="createAnother__"]');
          if (desiredElement) {
            setTimeout(() => {
              this.setFormValues();
            }, 50);
          }
        });
        obs.disconnect();
      }
    });

    this.mutationObserver.observe(document.body, { childList: true, subtree: true });
  }

  private attachPopupButtonClickListener(): void {
    this.popupButtonMutationObserver = new MutationObserver((mutations, observer) => {
      const popupButton = document.querySelector('button[data-test="popup-button"]');
      if (popupButton) {
        this.renderer.listen(popupButton, 'click', () => {
          setTimeout(() => {
            this.setFormValues();
          }, 50);
        });
        observer.disconnect();
      }
    });
    this.popupButtonMutationObserver.observe(document.body, {
      childList: true,
      subtree: true,
    });
  }
}
