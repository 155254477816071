import { Component, OnInit } from '@angular/core';
import { MatomoService } from 'form-controls';
import { MenuService } from '../app-base/services/menu.service';
import { menuItems } from './app.menu';
import { HeaderService } from '../app-base/services/header.service';
import { LogoutService } from './services/logout.service';
import { AccessRulesService } from './services/access-rules.service';
import { FaviconService } from './services/favicon.service';

@Component({
  selector: 'cm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isLoading = true;

  showPdfViewer = true;

  constructor(
    private menuService: MenuService,
    private headerService: HeaderService,
    private logoutSvc: LogoutService,
    private matomoSvc: MatomoService,
    private accessRulesService: AccessRulesService,
    private faviconService: FaviconService,
  ) {
    this.menuService.items = menuItems;
  }

  ngOnInit() {
    (window as any).matomoSiteId = this.accessRulesService.getEnvironmentSettings().matomoSiteId;

    setTimeout(() => {
      this.showPdfViewer = false;
    }, 1000);

    const linkEl = document.head.querySelector('link[href*="styles"]');
    this.isLoading = !(linkEl as HTMLLinkElement).sheet;
    (linkEl as HTMLLinkElement).addEventListener('load', () => {
      this.isLoading = false;
    });

    this.faviconService.setFavicon(this.accessRulesService.getEnvironmentProject().favicon);

    this.headerService.userControls = [
      {
        label: 'Log Out',
        onClick: () => this.logoutSvc.logout('Manual Log Out'),
      },
    ];
  }

  createNotification($event: any) {
    if ($event.type === 'error') {
      this.matomoSvc.sendNotificationEvent('Error', $event.content || '-');
    }
  }
}
