/* eslint-disable prefer-destructuring */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CmInputComponent } from 'form-controls';
import { takeUntil } from 'rxjs/operators';
import { LoginPayload } from 'src/app/models/login/login-payload.model';
import { LoginResponse } from 'src/app/models/login/login-response.model';
import { AppService } from 'src/app/services/app.service';
import { LoginService } from 'src/app/services/general/login.service';
import { MetaService } from 'src/app/services/meta.service';
import { SessionService } from 'src/app/services/session.service';
import { ComponentDestroyClass } from 'src/app/shared/classes/component-destroy.class';
import { customRequiredValidator, isValidPin } from 'src/app/shared/utils/form-validators';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import { MatDialog } from '@angular/material/dialog';
import { CheckProjectVersionGuard } from 'src/app/guards/check-project-version.guard';
import { ProjectService } from 'src/app/services/project.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FirstLoginComponent } from '../../cms/modals/first-login/first-login.component';
import { AccessRulesService } from '../../services/access-rules.service';

@Component({
  selector: 'cms-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [LoginService],
})
export class LoginComponent extends ComponentDestroyClass implements OnInit, OnDestroy {
  @ViewChild('passwordControl', { static: true }) passwordControl: CmInputComponent;

  @ViewChild('pinControl', { static: false }) pinControl: CmInputComponent;

  form: FormGroup;

  loading = false;

  passwordVisibility = false;

  pinRequired = false;

  error: string = null;

  status: number = null;

  private errorTimeout = null;

  private readonly REQUIRED_MESSAGE = 'The field should be filled in';

  private readonly HIDE_ERROR_DELAY = 3 * 60 * 1000; // ms

  constructor(
    private loginSvc: LoginService,
    private fb: FormBuilder,
    private sessionSvc: SessionService,
    private appSvc: AppService,
    private metaSvc: MetaService,
    private router: Router,
    private storageSvc: StorageService,
    private dialog: MatDialog,
    private checkProjectVersionGuard: CheckProjectVersionGuard,
    private projectService: ProjectService,
    private accessRulesService: AccessRulesService,
    private sanitizer: DomSanitizer,
  ) {
    super();
  }

  ngOnInit(): void {
    this.metaSvc.setTitle('Login Page');
    this.buildForm();
    this.updateFormWithRememberMeData();
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  get projectInfo() {
    return this.accessRulesService.getEnvironmentProject();
  }

  get logo() {
    return this.sanitizeBase64(this.accessRulesService.getLogoBase64());
  }

  get loginBtnDisabled() {
    return this.loading || !this.form.valid;
  }

  forgotUsername() {
    this.router.navigate(['login-email']);
  }

  onSubmit() {
    this.loading = true;
    const formValue: LoginPayload = this.form.value;

    this.loginSvc
      .login(formValue)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe({
        next: (response: LoginResponse) => {
          this.clearSessionStorage(formValue.login);
          this.setRememberMeData(formValue);
          this.sessionSvc.token = response.accessToken;
          this.sessionSvc.tokenExpiresIn = response.accessTokenValidity;
          this.clearErrorTimeout();

          this.checkProjectVersionGuard.checkProjectVersion().subscribe({
            next: (checked: boolean) => {
              if (checked) {
                this.appSvc
                  .goToLastPage()
                  .then(() => {
                    this.loading = false;
                    if (this.isNeedShowFirstLoginModal()) {
                      this.showFirstLoginModal();
                    }
                  })
                  .catch((err) => console.error(err));
              }
            },
          });
        },
        error: (err: any) => {
          if (err.message.includes('A message with a 4-digit verification code')) {
            this.setPinAsRequired();
          }

          this.showError(err);
          this.loading = false;
        },
      });
  }

  onChangePasswordVisibility() {
    this.passwordVisibility = !this.passwordVisibility;
    const passwordEl: HTMLInputElement = this.passwordControl.inputElement.nativeElement;
    passwordEl.focus();
    this.putCursorEndText(passwordEl);
  }

  private sanitizeBase64(base64: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(base64);
  }

  private buildForm() {
    this.form = this.fb.group({
      login: [null, { validators: [customRequiredValidator(this.REQUIRED_MESSAGE)] }],
      password: [
        null,
        { validators: [customRequiredValidator(this.REQUIRED_MESSAGE), Validators.minLength(8)] },
      ],
      pin: [null],
      rememberMe: [true],
    });
  }

  private setPinAsRequired() {
    this.pinRequired = true;
    const pinControl = this.form.get('pin');
    pinControl.setValidators([customRequiredValidator(this.REQUIRED_MESSAGE), isValidPin]);
    pinControl.updateValueAndValidity();
    setTimeout(() => {
      this.pinControl.inputElement.nativeElement.focus();
    }, 0);
  }

  private putCursorEndText(element: HTMLInputElement) {
    const { value } = this.form.get('password');
    const len = (value && value.length) || 0;
    setTimeout(() => {
      element.setSelectionRange(len, len);
    }, 0);
  }

  private showError(err: any) {
    this.clearErrorTimeout();
    this.error = err.message;
    this.status = Number(err.error_type);

    this.errorTimeout = setTimeout(() => {
      this.hideError();
    }, this.HIDE_ERROR_DELAY);
  }

  private hideError() {
    this.error = null;
    this.status = null;
  }

  private clearErrorTimeout() {
    if (!this.errorTimeout) {
      return;
    }

    clearTimeout(this.errorTimeout);
  }

  private setRememberMeData(formValue: LoginPayload) {
    if (!formValue.rememberMe) {
      this.loginSvc.removeRememberMeData();
      return;
    }

    this.loginSvc.rememberMeData = {
      login: formValue.login,
      rememberMe: formValue.rememberMe,
    };
  }

  private updateFormWithRememberMeData() {
    const data = this.loginSvc.rememberMeData;

    if (!data) {
      return;
    }

    this.form.patchValue(data);
  }

  private clearSessionStorage(currentLogin: string) {
    const storageName = this.storageSvc.storageName;

    if (storageName && currentLogin !== storageName) {
      this.storageSvc.clear('SS');
    }

    this.storageSvc.storageName = currentLogin;
  }

  private isNeedShowFirstLoginModal(): boolean {
    const isShow = this.storageSvc.get('tutorial');
    return (
      !isShow &&
      this.projectService.validAccessRuleByProject(
        this.accessRulesService.getAccessRulesValue().common.login.show.first_login_modal,
      )
    );
  }

  private showFirstLoginModal() {
    this.dialog.open(FirstLoginComponent, {
      maxWidth: '600px',
      closeOnNavigation: true,
      data: { likeAPopup: true, withoutAction: true, withoutTopHr: true },
    });
  }
}
