import { Inject, Injectable } from '@angular/core';
import MatomoTracker from '@datapunt/matomo-tracker-js';
import { TrackEventParams } from '@datapunt/matomo-tracker-js/es/types';

@Injectable({
  providedIn: 'root',
})
export class MatomoService {
  private _tracker: MatomoTracker;

  private get tracker(): MatomoTracker {
    if (!this._tracker) {
      const siteId = (window as any).matomoSiteId || 99;
      this._tracker = new MatomoTracker({
        urlBase: 'https://wd1.urchinsys.com',
        siteId: siteId,
        disabled: false,
        heartBeat: {
          active: true,
          seconds: 10,
        },
        linkTracking: false,
        configurations: {
          disableCookies: true,
          setSecureCookie: true,
          setRequestMethod: 'POST',
        },
      });
    }
    return this._tracker;
  }

  constructor(@Inject('env') private env) {}

  trackPageView(title: string): void {
    this.tracker.trackPageView({
      documentTitle: title,
    });
  }

  trackEvent(params: TrackEventParams): void {
    this.tracker.trackEvent(params);
  }

  setUserId(userId: string): void {
    this.tracker.pushInstruction('setUserId', userId);
  }

  setCustomVariable(index: number, name: string, value: string, scope: string = 'visit'): void {
    this.tracker.pushInstruction('setCustomVariable', index, name, value, scope);
  }

  public sendNotificationEvent(type: string, message: string): void {
    this.trackEvent({
      category: `Notification type: ${type}`,
      action: message,
    });
  }
}
