/* eslint-disable class-methods-use-this */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserIs } from 'src/app/models/user-is.model';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { ModalLayoutData } from 'src/app/models/modal/modal-layout-data.model';
import { ProfileService } from 'src/app/services/profile.service';
import { ProjectService } from 'src/app/services/project.service';
import { AccessRulesService } from '../../../../../services/access-rules.service';

@Component({
  selector: 'cms-support-modal',
  templateUrl: './support-modal.component.html',
  styleUrls: ['./support-modal.component.scss'],
})
export class SupportModalComponent implements OnInit {
  readonly userIsEmployer: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalLayoutData,
    private profileSvc: ProfileService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<any>,
    private authorizationSvc: AuthorizationService,
    private projectService: ProjectService,
    private accessRulesService: AccessRulesService,
  ) {
    this.userIsEmployer = this.authorizationSvc.userIs([UserIs.CLIENT]);
  }

  ngOnInit(): void {}

  get projectInfo() {
    return this.accessRulesService.getEnvironmentProject();
  }

  get user() {
    return this.profileSvc.user;
  }

  get showContactUsItem() {
    return (
      this.userIsEmployer &&
      this.projectService.validAccessRuleByProject(
        this.accessRulesService.getAccessRulesValue().components.support_modal.show.section.contact_us,
      )
    );
  }

  get showHelpCenterItem() {
    return this.projectService.validAccessRuleByProject(
      this.accessRulesService.getAccessRulesValue().components.support_modal.show.section.help_center,
    );
  }

  closeSupportModal() {
    this.dialogRef.close();
  }
}
