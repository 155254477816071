import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_BASE_HREF, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { environment } from 'src/environments/environment';
import { FormControlsModule } from 'form-controls';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from 'ng2-tooltip-directive';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppBaseModule } from '../app-base/app-base.module';

import { LoginComponent } from './components/login/login.component';
import { SpinnerRectModule } from './shared/components/spinner-rect/spinner-rect.module';
import { ForgotUsernameComponent } from './components/forgot-username/forgot-username.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { UserTabsService } from './services/user-tabs.service';
import { RefreshSessionInterceptor } from './interceptors/refresh-session.interceptor';
import { SessionInterceptor } from './interceptors/session.interceptor';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { RegisterComponent } from './components/register/register.component';
import { RegisterResolver } from './components/register/register.resolver';
import { RegisterService } from './services/general/register.service';
import { ModalLayoutModule } from './shared/modals/modal-layout/modal-layout.module';
import { PrivatePolicyComponent } from './components/register/modals/private-policy/private-policy.component';
import { TermsUseAgreementComponent } from './components/register/modals/terms-use-agreement/terms-use-agreement.component';
import { TutorialComponent } from './components/register/modals/tutorial/tutorial.component';
import { ModalVideoModule } from './shared/modals/modal-video/modal-video.module';
import { CreatePasswordComponent } from './components/create-password/create-password.component';
import { AuthorizationComponent } from './components/authorization/authorization.component';
import { ControlsErrorsModule } from './shared/controls-errors.module';
import { UserRevokeModalModule } from './shared/modals/user-revoke-modal/user-revoke-modal.module';
import { DeployPopupComponent } from './components/deploy-popup/deploy-popup.component';
import { AccessRulesService } from "./services/access-rules.service";

export function initializeApp(accessRulesService: AccessRulesService) {
  return () => accessRulesService.initializeService();
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotUsernameComponent,
    ForgotPasswordComponent,
    PageNotFoundComponent,
    RegisterComponent,
    PrivatePolicyComponent,
    TermsUseAgreementComponent,
    TutorialComponent,
    CreatePasswordComponent,
    AuthorizationComponent,
    DeployPopupComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AppBaseModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormControlsModule.forRoot(environment),
    SimpleNotificationsModule.forRoot({ timeOut: 2500, showProgressBar: false }),
    HttpClientModule,
    SpinnerRectModule,
    ControlsErrorsModule,
    TooltipModule,
    ModalLayoutModule,
    ModalVideoModule,
    UserRevokeModalModule,
    NgxExtendedPdfViewerModule,
  ],
  providers: [
    // https://angular.io/guide/http#interceptor-order
    { provide: HTTP_INTERCEPTORS, useClass: SessionInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RefreshSessionInterceptor, multi: true },
    {
      provide: APP_BASE_HREF,
      useValue: '/',
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    RegisterResolver,
    RegisterService,
    AccessRulesService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AccessRulesService],
      multi: true,
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: AccessRulesInterceptor,
    //   multi: true,
    // },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private userTabsSvc: UserTabsService) {
    this.userTabsSvc.destroyPreviousSession();
  }
}
