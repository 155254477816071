import { Component } from '@angular/core';
import { AccessRulesService } from '../../../../services/access-rules.service';

@Component({
  selector: 'cms-private-policy',
  templateUrl: './private-policy.component.html',
  styleUrls: ['./private-policy.component.scss'],
})
export class PrivatePolicyComponent {
  constructor(private accessRulesService: AccessRulesService) {}

  get projectInfo() {
    return this.accessRulesService.getEnvironmentProject();
  }
}
