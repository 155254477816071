export enum ProjectIs {
  UTMC = 'UTMC',
  STOPCLAIMS = 'STOPCLAIMS',
  ACME = 'ACME',
}

export enum ProjectEnvironment {
  DEV = 'DEV',
  UAT = 'UAT',
  PROD = 'PROD',
}

export interface Environment {
  production: boolean;
}

export interface ProjectInfo {
  name: string;
  abbreviation: ProjectIs;
  phone: string;
  fax: string;
  email: string;
  address_line_one: string;
  address_line_two: string;
  url: string;
  parentProjectAbbreviation: string;
}
