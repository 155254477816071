<div class="logo-big">
  <img [src]="logo" />
</div>
<div class="register-container">
  <h3 class="text-center">Registration</h3>
  <div class="form-container">
    <form [formGroup]="form" novalidate (submit)="onSubmit()">
      <div *ngIf="!isRegistered" class="control margin-bottom">
        <label for="email">Email</label>
        <lib-cm-input
          placeholder=""
          formControlName="email"
          id="email"
          [titlecase]="false"
          [disabled]="true"
        ></lib-cm-input>
      </div>
      <div class="control margin-bottom">
        <label for="pin">PIN</label>
        <lib-cm-input
          placeholder=""
          formControlName="pin"
          id="pin"
          [titlecase]="false"
        ></lib-cm-input>
      </div>
      <div *ngIf="!isRegistered" class="control margin-bottom">
        <label for="login">Username</label>
        <lib-cm-input
          placeholder=""
          formControlName="login"
          id="login"
          [titlecase]="false"
        ></lib-cm-input>
        <span
          [tooltip]="usernameInfo"
          contentType="template"
          placement="right"
          class="material-icons username-info-icon"
          tooltipClass="username-info-message"
        >
          help
        </span>
      </div>
      <div *ngIf="!isRegistered" class="control password-input margin-bottom">
        <label for="password">Password</label>
        <lib-cm-input
          #passwordControl
          placeholder=""
          [type]="passwordVisibility ? 'text' : 'password'"
          formControlName="password"
          id="password"
          [titlecase]="false"
          (focus)="onPasswordFocus()"
        ></lib-cm-input>
        <div class="password-visibility" (click)="onChangePasswordVisibility()">
          <span *ngIf="passwordVisibility" class="material-icons"> visibility </span>
          <span *ngIf="!passwordVisibility" class="material-icons"> visibility_off </span>
        </div>
        <span
          [tooltip]="passwordInfo"
          contentType="template"
          placement="right"
          class="material-icons password-info-icon"
          tooltipClass="password-info-message"
        >
          info
        </span>

        <div *ngIf="showPasswordValidationRules" class="password-validation-rules mt-2">
          <p class="mb-1">Password must:</p>
          <ul>
            <li
              [ngClass]="{
                valid:
                  !passwordErrors?.custom?.required &&
                  !passwordErrors?.minlength &&
                  !passwordErrors?.maxlength,
                invalid:
                passwordErrors?.custom?.required || passwordErrors?.minlength || passwordErrors?.maxlength
              }"
            >
              Have at least 8 characters
            </li>
            <li
              [ngClass]="{
                valid: !passwordErrors?.custom?.required && !passwordErrors?.custom?.pattern,
                invalid: passwordErrors?.custom?.required || passwordErrors?.custom?.pattern
              }"
            >
              At least one of each: upper case, lower case, numeric, special character
            </li>
          </ul>
          <p class="mb-1">Password must NOT:</p>
          <ul>
            <li
              [ngClass]="{
                valid: !passwordErrors?.custom?.required && !passwordErrors?.containsSpaces,
                invalid: passwordErrors?.custom?.required || passwordErrors?.containsSpaces
              }"
            >
              Contain spaces
            </li>
            <li
              [ngClass]="{
                valid: !passwordErrors?.custom?.required && !passwordErrors?.containLoginMail,
                invalid: passwordErrors?.custom?.required || passwordErrors?.containLoginMail
              }"
            >
              Contain the Username or Email Address
            </li>
          </ul>
        </div>
      </div>

      <div *ngIf="!isRegistered" class="control password-input margin-bottom">
        <label for="repeatpass">Re-Enter Password</label>
        <lib-cm-input
          #repeatpassControl
          placeholder=""
          [type]="repeatpassVisibility ? 'text' : 'password'"
          formControlName="repeatpass"
          id="repeatpass"
          [titlecase]="false"
        ></lib-cm-input>
        <div class="password-visibility" (click)="onChangeRepeatpassVisibility()">
          <span *ngIf="repeatpassVisibility" class="material-icons"> visibility </span>
          <span *ngIf="!repeatpassVisibility" class="material-icons"> visibility_off </span>
        </div>
      </div>

      <div class="link margin-bottom">
        <a (click)="onOpenTermsUseAgreement()">Terms of Use Agreement</a>
        <a (click)="onOpenPrivacyPolicy()" class="float-right">CMS Privacy Policy</a>
      </div>

      <div class="control margin-bottom text-center check">
        <lib-cm-checkbox label="I have read and agree to the Terms of Use and Privacy Policy" formControlName="check2"></lib-cm-checkbox>
      </div>

      <button type="submit" class="register-btn margin-bottom" [disabled]="registerBtnDisabled">
        <div class="spinner-rect-container" [class.show]="loading">
          <spinner-rect color="white"></spinner-rect>
        </div>
        <div class="title" [class.not-visible]="loading">Register</div>
      </button>

      <div class="link margin-bottom text-center" *ngIf="showRegistrationVideoTutorialLink">
        <a (click)="openRegistrationTutorialModal()"><b>Watch Registration Video Tutorial</b></a>
      </div>

      <div *ngIf="error" class="error-container">
        <span class="error">
          <span class="error-message">
            {{ 'ERROR: ' + error }}
          </span>
        </span>
      </div>
    </form>
  </div>
</div>

<ng-template #usernameInfo>
  <span
    ><b>Username Creation Requirements:</b><br />
    Minimum Length – 6 characters<br />
    Maximum Length – 24 characters<br />
    Content Requirements – no spaces<br />
    Special Characters – only period (.), hyphen (-), and underscore (_) are allowed<br />
    Usernames are not case-sensitive</span
  >
</ng-template>
<ng-template #passwordInfo>
  <span
    ><b>Password Creation Requirements:</b><br />
    Passwords are case sensitive<br
  /></span>
</ng-template>
