import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { SharedModule } from 'src/app/cm/shared/shared.module';
import { TextMaskModule } from 'angular2-text-mask';
import { TooltipModule } from 'ng2-tooltip-directive';
import { ControlsErrorsModule } from 'src/app/shared/controls-errors.module';
import { SsnAutocompleteModule } from 'src/app/shared/components/ssn-autocomplete/ssn-autocomplete.module';
import { FormControlsModule } from 'projects/form-controls/src/lib/form-controls.module';
import { ErrorHandlerService } from 'src/app/services/general/error-handler.service';
import { BodyComponent } from './body/body.component';
import { ContentComponent } from './content/content.component';
import { HeaderComponent } from './header/header.component';
import { DetailsHeaderComponent } from './details-header/details-header.component';
import { ActionButtonsBarComponent } from './action-buttons-bar/action-buttons-bar.component';
import { TitleBarComponent } from './title-bar/title-bar.component';
import { LeftSidebarComponent } from './left-sidebar/left-sidebar.component';
import { MenuComponent } from './menu/menu.component';
import { MenuItemComponent } from './menu/menu-item/menu-item.component';
import { MenuService } from './services/menu.service';
import { SubMenuComponent } from './menu/sub-menu/sub-menu.component';
import { ScreenService } from './services/screen.service';
import { SidebarService } from './services/sidebar.service';
import { DetailsHeaderService } from './services/details-header.service';
import { FilterPanelComponent } from './left-sidebar/filter-panel/filter-panel.component';
import { HeaderTitleComponent } from './header/header-title/header-title.component';
import { UserControlsComponent } from './header/user-controls/user-controls.component';
import { ActionTabsPanelComponent } from './left-sidebar/action-tabs-panel/action-tabs-panel.component';
import { InfoBarComponent } from './info-bar/info-bar.component';
import { RawsInfoPanelComponent } from './left-sidebar/raws-info-panel/raws-info-panel.component';
import { LabelsComponent } from './left-sidebar/filter-panel/labels/labels.component';
import { OnlineUsersComponent } from './header/online-users/online-users.component';
import { UtmcUsersModalComponent } from './header/online-users/modal/utmc-users-modal/utmc-users-modal.component';
import { ModalLayoutModule } from '../app/shared/modals/modal-layout/modal-layout.module';
import { ReleaseModalComponent } from './header/online-users/modal/realease-modal/release-modal.component';
import {FaviconService} from "../app/services/favicon.service";

@NgModule({
  declarations: [
    BodyComponent,
    ContentComponent,
    HeaderComponent,
    DetailsHeaderComponent,
    ActionButtonsBarComponent,
    TitleBarComponent,
    LeftSidebarComponent,
    MenuComponent,
    MenuItemComponent,
    SubMenuComponent,
    FilterPanelComponent,
    HeaderTitleComponent,
    UserControlsComponent,
    ActionTabsPanelComponent,
    InfoBarComponent,
    RawsInfoPanelComponent,
    LabelsComponent,
    OnlineUsersComponent,
    UtmcUsersModalComponent,
    ReleaseModalComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormControlsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatMenuModule,
    FormsModule,
    SharedModule,
    ControlsErrorsModule,
    TextMaskModule,
    ModalLayoutModule,
    TooltipModule,
    SsnAutocompleteModule,
  ],
  exports: [BodyComponent, ContentComponent],
  providers: [
    MenuService,
    FaviconService,
    ScreenService,
    SidebarService,
    DetailsHeaderService,
    ErrorHandlerService,
  ],
})
export class AppBaseModule {}
