<div class="dialog-title">
  <h2 mat-dialog-title>Hi {{ user.firstName }} {{ user.lastName }}, how can we help you?</h2>

  <button mat-button mat-dialog-close class="close-btn-icon">
    <i class="material-icons">close</i>
  </button>
</div>

<mat-dialog-content class="content-block">
  <div class="help-center-section" *ngIf="showHelpCenterItem">
    <ul class="common-questions-list">
      <li class="common-questions-list__item">
        <a routerLink="/dashboard/articles" (click)="closeSupportModal()">{{ projectInfo.name }} Help Center</a>
      </li>
    </ul>
  </div>
</mat-dialog-content>
