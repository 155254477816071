import { Component } from '@angular/core';
import { AccessRulesService } from '../../../../services/access-rules.service';

@Component({
  selector: 'cms-terms-use-agreement',
  templateUrl: './terms-use-agreement.component.html',
  styleUrls: ['./terms-use-agreement.component.scss'],
})
export class TermsUseAgreementComponent {
  constructor(private accessRulesService: AccessRulesService) {}

  get projectInfo() {
    return this.accessRulesService.getEnvironmentProject();
  }
}
